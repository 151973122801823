import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactSelect from "react-select";
import { DefaultButton } from "../../../styles/StyledComponents";
import styled from "styled-components";

const DescriptionInput = styled.input`
  margin: 10px 0px;
  width: auto;
  padding: 10px;
`;

const ItemList = ({ items, addItemToCart, createWarning }) => {
  const [input, setInput] = useState("");
  const [description, setDescription] = useState<string>("");
  const [quantity, setQuantity] = useState(null);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const addItem = () => {
    if (!selectedItem) {
      createWarning("Please select an item");
      return;
    }

    const item = selectedItem.item;
    if (!item) return;
    if (quantity <= 0 || parseInt(quantity) < 0) {
      createWarning("Please enter a valid quanity");
      return;
    }
    let item_obj = {
      ...item,
      item_id: item.id,
      quantity: quantity,
      accuracy: -1,
      type_id: item.material_index,
      upload_filename: "",
      description: description,
    };
    setDescription("");
    addItemToCart(item_obj);
  };

  const createData = (items) => {
    if (!items) return;
    let data = items
      .filter((item) => item.name.toLowerCase().includes(input.toLowerCase()))
      .map((item) => {
        return {
          label: item.name,
          value: item.id,
          item: {
            ...item,
          },
        };
      });
    return data;
  };

  const handleQuantChange = (e) => {
    const r = /[^0-9.]/g;
    if (String(e.target.value).search(r) > -1) {
      return;
    } else {
      if (parseInt(e.target.value) > 99) {
        setQuantity(99);
      } else if (parseInt(e.target.value) < 0) {
        setQuantity(quantity);
      } else if (e.target.value === "") {
        setQuantity(null);
      } else {
        setQuantity(parseInt(e.target.value));
      }
    }
  };

  const data = createData(items) || null;

  return (
    <div>
      {items ? (
        <>
          <div style={{ minWidth: "400px", display: "flex" }}>
            <input
              style={{ maxWidth: "40px", marginRight: "15px" }}
              id="standard-number"
              type="number"
              onChange={handleQuantChange}
              placeholder={"0"}
              value={quantity || ""}
            />
            <div style={{ flex: ".99" }}>
              <ReactSelect
                onChange={(e) => {
                  setSelectedItem(e);
                }}
                options={data}
                styles={{
                  container: (provided, state) => {
                    return { ...provided, height: "100%" };
                  },
                  control: (provided, state) => {
                    return { ...provided, height: "100%" };
                  },
                }}
                placeholder="Search for an item"
              />
            </div>
            <DefaultButton
              style={{ marginLeft: "10px" }}
              onClick={addItem}
              square
            >
              Add
            </DefaultButton>
          </div>
          <DescriptionInput
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder={"Description (optional)"}
            value={description || ""}
          />
        </>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default ItemList;

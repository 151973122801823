import React, { Fragment, useState } from "react";
import { DefaultButton } from "../../styles/StyledComponents";
import { ListSummary } from "./Components/ListSummary";
import { ListSelect } from "./Components/ManualUploadListSelect";
import styled from "styled-components";
import { ProjectsEntity } from "../../types/list";

const ErrorMessage = styled.p`
  color: red;
`;

const SelectListContainer = ({ lists, setSelectedList }) => {
  const [inputList, setInputList] = useState(undefined);
  const [inputProject, setInputProject] = useState(undefined);
  const [err, setErr] = useState<string>("");

  const createErr = (errorMsg: string) => {
    setErr(errorMsg);
    setTimeout(() => {
      setErr("");
    }, 3000);
  };

  const selectList = (e) => {
    let list = lists.filter((list) => list.id === e.value);
    list = list[0];
    setInputList(list);
  };

  return (
    <Fragment>
      {err && <ErrorMessage>{err}</ErrorMessage>}
      <ListSelect onChange={selectList} lists={lists} />
      {inputList ? (
        <ListSummary
          name={inputList.name}
          summary={inputList.slogan}
          image={inputList.start_image_filename}
          projects={inputList.projects.filter((p: ProjectsEntity) =>
            parseInt(p.verified, 10)
          )}
          setSelectedProject={setInputProject}
        />
      ) : null}

      {inputList ? (
        <DefaultButton
          onClick={() => {
            if (!inputProject) {
              createErr("A project is required");
              return;
            }
            setSelectedList(inputList, inputProject);
          }}
          square
        >
          {"Select >"}
        </DefaultButton>
      ) : null}
    </Fragment>
  );
};

export default SelectListContainer;

import React from "react";
import { DefaultButton } from "../../../styles/StyledComponents";
import { ButtonsWrapper } from "./ButtonsWrapper";

const SummaryButtons = ({
  prevContent,
  nextContent,
  onPrevClick,
  onNextClick,
}) => {
  return (
    <ButtonsWrapper>
      <DefaultButton onClick={onPrevClick} square>
        {prevContent}
      </DefaultButton>
      <DefaultButton onClick={onNextClick} square>
        {nextContent}
      </DefaultButton>
    </ButtonsWrapper>
  );
};

export default SummaryButtons;

import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

const MAPQUEST = "3EZ5utA1epXTFMu8G5Rhl7ks889Gb7h9";

const MapContainer = styled.div`
  z-index: 1;
  margin: 10px;
  min-width: 600px;
`;

const MapSelect = ({ setLatLng, setLocation }) => {

  if(typeof window === "undefined"){
    return null;
  }
  
  const L = require("leaflet")

  const BASEMAP = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const ATTRIBUTION =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

  const map = useRef(undefined);
  const mark = useRef(undefined);

  const addMarker = (e) => {
    if (mark.current) {
      map.current.removeLayer(mark.current);
    }

    //@ts-ignore
    mark.current = new L.marker(e.latlng);
    mark.current.bindPopup(
      `(${e.latlng.lat.toPrecision(4)}, ${e.latlng.lng.toPrecision(4)})`
    );
    mark.current.addTo(map.current);
    mark.current.openPopup();
    setLatLng([e.latlng.lat, e.latlng.lng]);
  };

  const zoomAndMarkLatLong = (latlng) => {
    addMarker({ latlng });
    const latLngs = [mark.current.getLatLng()];
    const markerBounds = L.latLngBounds(latLngs);
    map.current.flyToBounds(markerBounds, { maxZoom: 10 });
  };

  const createMap = useCallback(() => {
    if (!map.current) {
      map.current = L.map("map", { maxZoom: 22, dragging: true }).setView(
        [30, 0],
        2
      );
      L.tileLayer(BASEMAP, {
        maxNativeZoom: 19,
        maxZoom: 22,
        attribution: ATTRIBUTION,
      }).addTo(map.current);
      map.current.on("click", addMarker);
      return;
    }
  }, []);

  useEffect(() => {
    //@ts-ignore
    let search = window.placeSearch({
      key: MAPQUEST,
      container: document.querySelector("#place-search-input"),
      collection: ["address", "adminArea"],
    });
    search.on("change", (e) => {
      setLocation(`${e.result.city}, ${e.result.countryCode}`);
      zoomAndMarkLatLong(e.result.latlng);
    });
  }, []);

  useEffect(() => {
    createMap();
  }, [createMap]);

  return (
    <>
      <div style={{ margin: "10px" }}>
        <input
          style={{ zIndex: 2 }}
          type="search"
          id="place-search-input"
          placeholder="Start Searching..."
        />
      </div>
      <MapContainer>
        <div style={{ height: "400px" }} id="map"></div>
      </MapContainer>
    </>
  );
};

export default MapSelect;

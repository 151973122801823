import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { DefaultButton } from "../../styles/StyledComponents";
import { useWarning } from "../../util/hooks";
import { ButtonsWrapper } from "./Components/ButtonsWrapper";
import MapSelect from "./Components/MapSelect"

const MapSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MapSelectContainer = ({ submitLatLng, prevScreen }) => {
  const [latlng, setLatLng] = useState([undefined, undefined]);
  const [location, setLocation] = useState(null);
  const [warning, createWarning] = useWarning();

  return (
    <Fragment>
      <MapSelectWrapper>
        <h2 style={{ margin: "10px" }}>Select the cleanup location.</h2>
        {warning ? <p className="login-error">{warning}</p> : null}
        <MapSelect setLocation={setLocation} setLatLng={setLatLng} />
        <ButtonsWrapper>
          <DefaultButton
            onClick={() => {
              prevScreen();
            }}
            square
          >
            {"< Prev"}
          </DefaultButton>
          <DefaultButton
            onClick={() => {
              if (!latlng[0] || !latlng[1]) {
                createWarning("Please select a location.");
                return;
              }
              submitLatLng(latlng, location);
            }}
            square
          >
            {"Next >"}
          </DefaultButton>
        </ButtonsWrapper>
      </MapSelectWrapper>
    </Fragment>
  );
};

export default MapSelectContainer;

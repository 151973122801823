import * as React from "react";
import UploadData from "../components/ManualUpload/index";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import Helmet from "react-helmet";

const ManualUpload = () => {
  return (
    <Layout vertical={true}>
      <Helmet>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://api.mqcdn.com/sdk/place-search-js/v1.0.0/place-search.css"
        />
        <script src="https://api.mqcdn.com/sdk/place-search-js/v1.0.0/place-search.js"></script>
      </Helmet>
      <Seo title="Manual Upload" />
      <UploadData />
    </Layout>
  );
};

export default ManualUpload;

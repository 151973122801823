/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { useTransition, animated as a } from "react-spring";
import {
  MDTModal,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../../styles/StyledComponents";
import SelectListContainer from "./SelectListContainer";
import AddItemsContainer from "./AddItemsContainer";
import MapSelectContainer from "./MapSelectContainer";
import SummaryContainer from "./SummaryContainer";
import Fail from "./Fail";
import Success from "./Success";
import { useListsData } from "../../util/useListsData";
import SurveyContainer from "./Components/SurveyContainer";
import { ListType } from "../../types/list";

const UploadData = () => {
  const { lists } = useListsData();
  const [selectedList, setSelectedList] = useState<ListType | undefined>(
    undefined
  );
  const [selectedProject, setSelectedProject] = useState<number | undefined>(
    undefined
  );
  const [latlng, setLatLng] = useState([undefined, undefined]);
  const [location, setLocation] = useState(null);
  const [surveyResults, setSurveyResults] = useState<string>(null);
  const [itemCart, setItemCart] = useState([]);

  const [index, setIndex] = useState(0);

  const submitList = (list, project) => {
    setItemCart([]);
    setSelectedList(list);
    setSelectedProject(project);
    toggleNextScreen();
  };

  const submitCart = (cart) => {
    setItemCart(cart);
    toggleNextScreen();
  };

  const submitSurvey = (surveyAnswers) => {
    const surveyAnswersStr = JSON.stringify(surveyAnswers);
    setSurveyResults(surveyAnswersStr);
    toggleNextScreen();
  };

  const submitLatLng = (latlng, location) => {
    setLatLng(latlng);
    setLocation(location);
    toggleNextScreen();
  };

  const prevScreen = () => {
    togglePrevScreen();
  };

  const prevScreenMap = () => {
    setIndex(selectedList.post_survey === null ? index - 2 : index - 1);
  };

  const components = [
    {
      name: "select",
      component: () => (
        <SelectListContainer lists={lists} setSelectedList={submitList} />
      ),
    },
    {
      name: "addItems",
      component: () => (
        <AddItemsContainer
          prevScreen={prevScreen}
          initialCart={itemCart}
          list={selectedList}
          submitCart={submitCart}
        />
      ),
    },
    {
      name: "survey",
      component: () => (
        <SurveyContainer
          prevScreen={prevScreen}
          submitSurvey={submitSurvey}
          list={selectedList}
          nextScreen={toggleNextScreen}
        />
      ),
    },
    {
      name: "mapSelect",
      component: () => (
        <MapSelectContainer
          submitLatLng={submitLatLng}
          prevScreen={prevScreenMap}
        />
      ),
    },
    {
      name: "summary",
      component: () => (
        <SummaryContainer
          prevScreen={prevScreen}
          latlng={latlng}
          itemCart={itemCart}
          list={selectedList}
          project={selectedProject}
          location={location}
          surveyResults={surveyResults}
          showFail={showFail}
          showSuccess={showSuccess}
        />
      ),
    },
    {
      name: "fail",
      component: () => <Fail />,
    },
    {
      name: "success",
      component: () => <Success />,
    },
  ];

  const transitions = useTransition(index, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });

  const toggleNextScreen = useCallback(() => {
    setIndex(index + 1 > components.length - 1 ? index : index + 1);
  }, [index]);

  const togglePrevScreen = useCallback(() => {
    setIndex(index === 0 ? index : index - 1);
  }, [index]);

  const showFail = useCallback(() => {
    let index = components.findIndex((entry) => {
      return entry.name === "fail";
    });

    setIndex(index);
  }, [index]);

  const showSuccess = useCallback(() => {
    let index = components.findIndex((entry) => {
      return entry.name === "success";
    });

    setIndex(index);
  }, [index]);

  return (
    <ModalWrapper>
      <MDTModal>
        <ModalTitle>Upload Data</ModalTitle>
        <ModalContent>
          {transitions((style, item) => {
            const Page = components[item].component;
            return (
              <a.div style={style}>
                <Page />
              </a.div>
            );
          })}
        </ModalContent>
      </MDTModal>
    </ModalWrapper>
  );
};

export default UploadData;

import { useEffect, useState } from "react";
import { getLists } from "../mdtApi/getLists";
import { ListType } from "../types/list";

export const useListsData = () => {
  const [lists, setLists] = useState<ListType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await getLists();
      setLists(data);
      setLoading(false);
    })();
  }, []);

  return { lists, loading };
};

import React from "react";
import styled from "styled-components";
import { apiUrl } from "../../../util/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../styles/colors";

const ItemButtonContent = styled.div`
  color: white;
  text-align: center;
`;

const ItemButtonWrapper = styled.div`
  background-color: #62789d;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
  align-items: center;
  box-shadow: 2px 2px 2px 2px #80808021;
`;

const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  margin: 5px;
`;

const ItemButtonImg = styled.img`
  width: 15%;
  background-color: #62789d;
  margin-right: 5px;
`;

const CartList = ({
  itemCart,
  removeItem,
  editable = true,
}: {
  itemCart?: any;
  removeItem?: any;
  editable?: any;
}) => {
  return (
    <CartWrapper>
      {itemCart.reverse().map((item, i) => (
        <ItemButtonWrapper key={i} square>
          <ItemButtonImg
            src={`${apiUrl}/images/${
              item.image_url ? item.image_url : "36_other.png"
            }`}
            alt={"other"}
          />
          <ItemButtonContent>
            <div>{`${item.quantity} ${item.name}`}</div>
            <div>{`${item.description ? item.description : ""}`}</div>
          </ItemButtonContent>
          {editable ? (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              color={colors.white}
              icon={faTimes}
              onClick={() => {
                if (editable) removeItem(item);
              }}
              size="5x"
            />
          ) : null}
        </ItemButtonWrapper>
      ))}
    </CartWrapper>
  );
};

export default CartList;

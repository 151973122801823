import React from "react";
import CartList from "../Components/CartList";

const Summary = ({ name, setName, date, setDate, itemCart }) => {
  return (
    <div>
      <div className="mq-place-search">
        <input
          className="mq-input"
          type="text"
          value={name}
          placeholder="Name this collection event"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "10px" }} className="mq-place-search">
        <input
          className="mq-input"
          type="date"
          value={date}
          placeholder="Name this collection event"
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
      </div>
      <h2>{`Logged Items: ${itemCart.reduce(
        (sum, item) => (sum += item.quantity),
        0
      )}`}</h2>
      <CartList editable={false} itemCart={itemCart} />
    </div>
  );
};

export default Summary;
import { apiUrl } from "../urls";

export default async (data) => {
  try {
    let res = await fetch(`${apiUrl}/logManualCollectionEvent.php`, {
      method: "POST",
      body: JSON.stringify(data),
    });
    let parsedRes = res.json();
    return parsedRes;
  } catch (e) {
    console.error(e);
    return null;
  }
};

import Skeleton from "react-loading-skeleton";
import React from "react";
import { apiUrl } from "../../../util/urls";
import { ProjectSelect } from "./ManualUploadListSelect";

export const ListSummary = ({ name, summary, image, projects, setSelectedProject  }) => {
  return (
    <div>
      <img
        style={{ maxWidth: "250px", padding: "10px" }}
        src={`${apiUrl}/images/${image}`}
        alt={name}
      />
      <p>{summary || <Skeleton count={5} />}</p>
      <div>
      <ProjectSelect onChange={(project)=>{setSelectedProject(project.value)}} projects={projects ? projects : []}/>
      </div>
    </div>
  );
};
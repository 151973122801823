import React from "react";
import { DefaultButton } from "../../styles/StyledComponents";
import { ButtonsWrapper } from "./Components/ButtonsWrapper";
import { navigate } from "gatsby";

const Success = () => {
  const returnHome = () => {
    navigate("/");
  };

  return (
    <div>
      <h2>Data submitted succesfully</h2>
      <ButtonsWrapper>
        <DefaultButton square onClick={returnHome}>
          Return Home
        </DefaultButton>
      </ButtonsWrapper>
    </div>
  );
};

export default Success;

import Skeleton from "react-loading-skeleton";
import ReactSelect from "react-select";
import React from "react";
import { ProjectsEntity } from "../../../types/list";

export const ProjectSelect = ({ projects, onChange }) => {
  const data: { label: string; value: number }[] = projects.map(
    (project: ProjectsEntity) => {
      return {
        label: project.name,
        value: project.id,
      };
    }
  );

  const defaultProject =
    data.length > 0 && data.filter((project) => project.label === "Default")[0];

  onChange(defaultProject);

  return (
    <div style={{ marginBottom: "20px" }}>
      {data.length > 0 ? (
        <ReactSelect
          onChange={onChange}
          defaultValue={defaultProject}
          options={data}
          placeholder="Select a Project"
        />
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export const ListSelect = ({ lists, onChange }) => {
  const data = lists.map((list) => {
    return {
      label: list.name,
      value: list.id,
    };
  });
  return (
    <div style={{ minWidth: "500px" }}>
      {lists.length > 0 ? (
        <ReactSelect
          onChange={onChange}
          options={data}
          placeholder="Select a List"
        />
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

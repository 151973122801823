import React, { useContext, useState } from "react";
import dayjs from "dayjs";
// import { logManualEvent } from "../../../util/api";
import Summary from "./Components/Summary";
import SummaryButtons from "./Components/SummaryButtons";
import { useWarning } from "../../util/hooks";
import { DebrisTrackerContext } from "../../context/DebrisTrackerContext";
import logManualEvent from "../../util/data/logManualEvent";

const createSubmissionData = (data) => {
  return {
    survey: data.survey,
    surveyResults: data.surveyResults,
    log: data.itemCart,
    track: null,
    images: null,
    password: "penguin",
    id: data.user_id,
    timestamp: dayjs(data.date).format("YYYY-MM-DD HH:mm:ss"),
    name: data.name,
    latitude: data.latlng[0],
    longitude: data.latlng[1],
    location: data.location,
    project: data.project,
  };
};

const SummaryContainer = ({
  list,
  itemCart,
  latlng,
  location,
  project,
  prevScreen,
  showFail,
  showSuccess,
  surveyResults,
}) => {
  const { user } = useContext(DebrisTrackerContext);

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [warning, createWarning] = useWarning();

  const onPrevClick = () => {
    prevScreen();
  };

  const onNextClick = () => {
    if (!name || name.length > 255) {
      createWarning("Please create a valid title for this event");
      return;
    } else if (!date) {
      createWarning("Please enter a date");
      return;
    }
    (async () => {
      let data = createSubmissionData({
        list,
        itemCart,
        name,
        latlng,
        date,
        user_id: user.id,
        location,
        survey: list.post_survey,
        surveyResults,
        project,
      });
      try {
        let res = await logManualEvent(data);
        if (res.success) {
          showSuccess();
        }
      } catch (e) {
        showFail();
      }
    })();
  };

  return (
    <>
      {warning ? <p className="login-error">{warning}</p> : null}
      <Summary
        name={name}
        setName={setName}
        date={date}
        setDate={setDate}
        itemCart={itemCart}
      />
      <SummaryButtons
        nextContent={`Submit to ${list.name} >`}
        prevContent={`< Prev`}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
      />
    </>
  );
};

export default SummaryContainer;

import React, { useEffect, useState } from "react";
import { DefaultButton } from "../../../styles/StyledComponents";
import { ButtonsWrapper } from "./ButtonsWrapper";
import styled from "styled-components";
import ReactSelect, { OptionsType } from "react-select";

interface Question {
  multi?: boolean;
  options: string[];
  other: boolean;
  other_name: string;
  required: boolean;
  text: string;
  type: "mcq";
}

interface Survey {
  questions: Question[];
  title: string;
}

const ContentWrapper = styled.div`
  min-width: 40vw;
`;

const SurveyQuestionsWrapper = styled.div``;

const SurveyTitle = styled.h2`
  margin-bottom: 0px;
`;

const QuestionWrapper = styled.div`
  margin: 10px 0px;
`;

const QuestionText = styled.h3`
  margin-bottom: 5px;
`;

const QuestionInput = styled.input`
  margin: 10px 0px;
  width: 96%;
  padding: 10px;
`;

const QuestionRequired = styled.span`
  color: red;
`;

const handleSelectChange = (
  event: OptionsType<{ value: string; label: string }>
) => {
  const resultArr = event.map((obj) => obj.value);
  if (resultArr.length === 0) {
    return [""];
  }
  return resultArr;
};

const SurveyQuestion = ({
  question,
  setAnswer,
}: {
  question: Question;
  setAnswer: (a: string | string[], arr: boolean) => void;
}) => (
  <QuestionWrapper>
    <QuestionText>
      {question.text}{" "}
      <QuestionRequired>{question.required ? "*" : ""}</QuestionRequired>
    </QuestionText>
    {question.multi ? (
      //Multi select
      <ReactSelect
        isMulti
        options={question.options.map((q) => {
          return { value: q, label: q };
        })}
        onChange={(e) => {
          setAnswer(handleSelectChange(e), true);
        }}
      />
    ) : (
      <QuestionInput
        onChange={(e) => {
          setAnswer(e.target.value, false);
        }}
      />
    )}
  </QuestionWrapper>
);

const SurveyContainer = ({ prevScreen, list, nextScreen, submitSurvey }) => {
  const [surveyAnswers, setSurveyAnswers] = useState<(string | string[])[]>([]);
  const [surveyParsed, setSurveyParsed] = useState<Survey | undefined>(
    undefined
  );
  const [verified, setVerified] = useState<boolean>(false);

  if (list.post_survey === null) {
    nextScreen();
  }

  useEffect(() => {
    const newSurveyParsed: Survey = JSON.parse(list.post_survey);
    newSurveyParsed && setSurveyParsed(newSurveyParsed);
    newSurveyParsed &&
      setSurveyAnswers(
        newSurveyParsed.questions.map(() => {
          return [""];
        })
      );
    if (
      newSurveyParsed &&
      newSurveyParsed.questions.filter((q) => q.required).length === 0
    ) {
      setVerified(true);
    }
  }, []);

  const handleAnswer = (index: number) => {
    return (answer: string, arr: boolean) => {
      const newAnswer = arr ? [...answer] : [answer];
      const newSurveyAnswers = [
        ...surveyAnswers.slice(0, index),
        newAnswer,
        ...surveyAnswers.slice(index + 1, surveyAnswers.length),
      ];

      setVerified(
        surveyParsed &&
          surveyParsed.questions.reduce((bool, curr, i) => {
            return curr.required ? newSurveyAnswers[i] !== "" : true || bool;
          }, false)
      );
      setSurveyAnswers(newSurveyAnswers);
    };
  };

  return (
    <ContentWrapper>
      <SurveyTitle>{surveyParsed && surveyParsed.title}</SurveyTitle>
      <p>
        Fields marked with a <QuestionRequired>*</QuestionRequired> are
        required.
      </p>
      <SurveyQuestionsWrapper>
        {surveyParsed &&
          surveyParsed.questions.map((question, i) => (
            <SurveyQuestion question={question} setAnswer={handleAnswer(i)} />
          ))}
      </SurveyQuestionsWrapper>
      <ButtonsWrapper>
        <DefaultButton
          onClick={() => {
            prevScreen();
          }}
          square
        >
          {"< Prev"}
        </DefaultButton>
        <DefaultButton
          style={{
            opacity: verified ? "100" : ".7",
            cursor: verified ? "pointer" : "default",
          }}
          onClick={() => {
            const allRequired =
              surveyParsed &&
              surveyParsed.questions.reduce((prev, curr, i) => {
                return prev && curr.required ? surveyAnswers[i] !== "" : true;
              }, false);
            if (allRequired) {
              submitSurvey(surveyAnswers);
            } else {
              return;
            }
          }}
          square
        >
          {"Next >"}
        </DefaultButton>
      </ButtonsWrapper>
    </ContentWrapper>
  );
};

export default SurveyContainer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useWarning } from "../../util/hooks";
import { getItemTypes } from "../../mdtApi/getData";
import { DefaultButton } from "../../styles/StyledComponents";
import { ButtonsWrapper } from "./Components/ButtonsWrapper";
import CartList from "./Components/CartList";
import ItemList from "./Components/ItemList";

const AddItemsWrapper = styled.div`
  min-width: 600px;
`;

const AddItemsContainer = ({ list, initialCart, submitCart, prevScreen }) => {
  const [items, setItems] = useState(undefined);
  const [itemCart, setItemCart] = useState(initialCart);
  const [warning, createWarning] = useWarning();

  const addItemToCart = (item) => {
    setItemCart([item, ...itemCart]);
  };

  const removeItem = (item) => {
    let index = itemCart.map((item) => item.item_id).indexOf(item.item_id);
    setItemCart([
      ...itemCart.slice(0, index),
      ...itemCart.slice(index + 1, itemCart.length),
    ]);
  };

  useEffect(() => {
    (async () => {
      const data = await getItemTypes(list.id);
      let categories = data.map((el) => el.category);
      categories = categories.filter((v, i, s) => s.indexOf(v) === i);
      const items = data.map((el) => {
        return { ...el, favorite: false };
      });
      let selectedCategories = {};
      categories.forEach((category) => {
        selectedCategories[`${category}`] = true;
      });
      setItems(items);
    })();
  }, [list.id]);

  return (
    <AddItemsWrapper>
      {warning ? <p className="login-error">{warning}</p> : null}
      <ItemList
        createWarning={createWarning}
        addItemToCart={addItemToCart}
        items={items}
      />
      <CartList itemCart={itemCart} removeItem={removeItem} />
      <ButtonsWrapper>
        <DefaultButton
          onClick={() => {
            prevScreen();
          }}
          square
        >
          {"< Prev"}
        </DefaultButton>
        <DefaultButton
          onClick={() => {
            if (itemCart.length < 1) {
              createWarning("Can't submit an empty cart");
            } else {
              submitCart(itemCart);
            }
          }}
          square
        >
          {"Next >"}
        </DefaultButton>
      </ButtonsWrapper>
    </AddItemsWrapper>
  );
};

export default AddItemsContainer;

import React from "react";
import { Router } from "@reach/router";
import { PrivateRoute } from "../Routing/PrivateRoute";
import UploadData from "./ManualUpload";

export default () => {
  return (
    <Router>
      <PrivateRoute path="/manual-upload" component={UploadData} />
    </Router>
  );
};

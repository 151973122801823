import React from "react";
import { DefaultButton } from "../../styles/StyledComponents";
import { ButtonsWrapper } from "./Components/ButtonsWrapper";
import { navigate } from "gatsby";

const Fail = () => {
  const returnHome = () => {
    navigate("/");
  };
  return (
    <div>
      <h2>
        Failed to submit data. Please try again. If the problem persists,
        contact us at debristracker101@gmail.com
      </h2>
      <ButtonsWrapper>
        <DefaultButton square onClick={returnHome}>
          Return Home
        </DefaultButton>
      </ButtonsWrapper>
    </div>
  );
};

export default Fail;

import fetch from "isomorphic-fetch";
import { apiUrl } from "../util/urls";
export const getLists = async () => {
  try {
    const res = await fetch(`${apiUrl}/getListsUpdated.php?allProjects=1`);
    const parsed = await res.json();

    if (!parsed.success) throw parsed.reason;

    return parsed.lists;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getMasterListSections = async () => {
  try {
    const res = await fetch(`${apiUrl}/getMasterList.php`);
    const parsed = await res.json();
    if (!parsed.success) throw parsed.reason;
    return parsed.master_list;
  } catch (e) {
    console.error(e);
    return [];
  }
};

import fetch from "isomorphic-fetch";
import { apiUrl, devAPI } from "../util/urls";
import dayjs from "dayjs";
import { convertToGeoJSON } from "../util/data/convertToGeoJSON";
/**
 * Helper function for getItemInstanceData
 * grabs one page of item instance data from the api
 */
const getItemData = async (page_no, page_size, from, to, user) => {
  try {
    let res = await fetch(
      `${apiUrl}/sendAllItemInstances.php?page=${page_no}&page_size=${page_size}&from=${from}&to=${to}${
        user ? `&user=${user}` : ""
      }`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    res = await res.json();
    return res;
  } catch (err) {
    return err;
  }
};

const getETAPItemData = async (page_no, page_size, from, to) => {
  const limit = 200000;
  let page = page_size;
  // alert("test");
  try {
    let params = {
      limit: limit.toString(),
      page: page.toString(),
      detail: "4",
      start: from,
      end: to,
    };
    let paramsString = new URLSearchParams(params).toString();
    let url = `${devAPI}/mdtEtaps/itemInstances?${paramsString}`;
    const response = await (await fetch(url)).json();
    return response;
  } catch (error) {
    console.error(error);
    throw {
      done: true,
      value: JSON.stringify(error),
    };
  }
};

/**
 * Convert data with shape [lat,long] to geojson.
 * This isn't being used currently but is just a nifty helper
 */
export const getItemInstanceGeoJSON = async (
  from: string,
  to: string,
  user = undefined
) => {
  try {
    let data = await getItemInstanceData(from, to, user);
    return convertToGeoJSON(data);
  } catch (err) {
    return err;
  }
};

// fetch all item instance data from mdt 2.0 api with detail 1
const asyncGetAllItems = async function* () {
  const limit = 200000;
  let page = 1;
  let pageCount = -1;

  while (page === 1 || page <= pageCount) {
    try {
      let params = {
        limit: limit.toString(),
        page: page.toString(),
        detail: "1",
      };
      let paramsString = new URLSearchParams(params).toString();
      let url = `${devAPI}/items?${paramsString}`;
      const response = await (await fetch(url)).json();
      pageCount = response["pageCount"];
      page++;
      yield response;
    } catch (error) {
      console.error(error);
      throw {
        done: true,
        value: JSON.stringify(error),
      };
    }
  }
};

// fetch all item instance data from mdt 2.0 api with detail 1
const asyncGetAllETAPItems = async function* () {
  const limit = 200000;
  let page = 1;
  let pageCount = -1;

  while (page === 1 || page <= pageCount) {
    try {
      let params = {
        limit: limit.toString(),
        page: page.toString(),
        detail: "4",
      };
      let paramsString = new URLSearchParams(params).toString();
      let url = `${devAPI}/mdtEtaps/itemInstances?${paramsString}`;
      const response = await (await fetch(url)).json();
      pageCount = response["pageCount"];
      page++;
      yield response;
    } catch (error) {
      console.error(error);
      throw {
        done: true,
        value: JSON.stringify(error),
      };
    }
  }
};

// fetch all item instance data from mdt 2.0 api with detail 1 and specified list id
const asyncGetItemsFromList = async function* (id) {
  const limit = 200000;
  let page = 1;
  let pageCount = -1;

  while (page === 1 || page <= pageCount) {
    try {
      let params = {
        limit: limit.toString(),
        page: page.toString(),
        detail: "1",
        list: id,
      };
      let paramsString = new URLSearchParams(params).toString();
      let url = `${devAPI}/items?${paramsString}`;
      const response = await (await fetch(url)).json();
      pageCount = response["pageCount"];
      page++;
      yield response;
    } catch (error) {
      console.error(error);
      throw {
        done: true,
        value: JSON.stringify(error),
      };
    }
  }
};

export const getAllETAPItemInstanceData = async (
  from: string = "0",
  to: string = dayjs().format("YYYY-MM-DD"),
  user: string = "",
  page_size: number = 50000
) => {
  const initial_page = 1;
  const data = [];

  try {
    let allItems = [];
    for await (const content of asyncGetAllETAPItems()) {
      allItems = allItems.concat(content.items);
    }
    return allItems;
  } catch (err) {
    return err;
  }
};

export const getAllItemInstanceData = async () => {
  try {
    let allItems = [];
    for await (const content of asyncGetAllItems()) {
      allItems = allItems.concat(content.items);
    }
    return allItems;
  } catch (err) {
    return err;
  }
};

export const getItemListInstanceData = async (id) => {
  try {
    let allItems = [];
    for await (const content of asyncGetItemsFromList(id)) {
      allItems = allItems.concat(content.items);
    }
    return allItems;
  } catch (err) {
    return err;
  }
};

export const getItemInstanceData = async (
  from: string = "0",
  to: string = dayjs().format("YYYY-MM-DD"),
  user: string = "",
  page_size: number = 50000
) => {
  const initial_page = 1;
  const data = [];
  try {
    const processedResponses = [];
    let res = await getItemData(initial_page, page_size, from, to, user);
    processedResponses.push(res.data);
    const num_pages = Math.ceil(res.total_records / page_size);
    const promises = [];
    for (let i = 2; i <= num_pages; i++) {
      promises.push(getItemData(i, page_size, from, to, user));
    }

    const results = await Promise.all(promises);

    results.forEach((res) => {
      processedResponses.push(res.data);
    });

    processedResponses.forEach((res) => {
      if (!res) return `Failed to pull list data`;
      data.push(...res);
    });

    return data;
  } catch (err) {
    return err;
  }
};

export const getETAPItemInstanceData = async (
  from: string = "0",
  to: string = dayjs().format("YYYY-MM-DD"),
  user: string = "",
  page_size: number = 50000
) => {
  const initial_page = 1;
  const data = [];
  try {
    const processedResponses = [];
    let res = await getETAPItemData(initial_page, page_size, from, to);

    processedResponses.push(res);
    const num_pages = Math.ceil(res.totalRecords / page_size);
    const promises = [];

    for (let i = 2; i <= num_pages; i++) {
      promises.push(getItemData(i, page_size, from, to, user));
    }

    const results = await Promise.all(promises);

    results.forEach((res) => {
      processedResponses.push(res.data);
    });

    return processedResponses[0].items;
  } catch (err) {
    return err;
  }
};

export const getSurveyDataByList = async (list_id, username, password) => {
  try {
    const res = await fetch(
      `${apiUrl}/getSurveyResults.php?username=${username}&password=${password}&list_id=${list_id}`
    );
    const parsedRes = await res.json();
    return parsedRes.data;
  } catch {
    return null;
  }
};

export const getItemsByList = async (
  list_id: string | undefined = undefined,
  user: string = "",
  password: string = "",
  from: string = "0",
  to: string = dayjs().format("YYYY-MM-DD"),
  default_page_size: number = 50000,
  user_id: string = ""
) => {
  try {
    let res = await fetch(
      `${apiUrl}/getLoggedDataJSON.php?username=${user}&password=${password}&lists=${list_id}&from=${from}&to=${to}&page=1&page_size=${default_page_size}&user_id=${user_id}`,
      { headers: { "Content-Type": "application/json" } }
    );
    let list_items = await res.json();

    const num_pages = Math.ceil(list_items.total_records / default_page_size);
    list_items = list_items.data;
    let promises = [];
    for (let i = 2; i <= num_pages; i++) {
      promises.push(
        fetch(
          `${apiUrl}/getLoggedDataJSON.php?username=${user}&password=${password}&lists=${list_id}&from=${from}&to=${to}&page=${i}&page_size=${default_page_size}&user_id=${user_id}`,
          { headers: { "Content-Type": "application/json" } }
        )
      );
    }
    let results = await Promise.all(promises);
    results.forEach((res) => {
      let data = res.data;
      list_items.push(...data);
    });
    return list_items;
  } catch {
    return null;
  }
};

export const getItemsByMdtETAP = async (
  project_id: string | undefined = undefined,
  from: string = "0",
  to: string = dayjs().format("YYYY-MM-DD"),
  default_page_size: number = 50000
) => {
  try {
    const processedResponses = [];
    let params = {
      project_id: project_id,
      start: from,
      end: to,
      detail: "4",
    };
    let paramsString = new URLSearchParams(params).toString();
    let url = `${devAPI}/mdtEtaps/itemInstances/${project_id}?${paramsString}`;

    const res = await (await fetch(url)).json();

    processedResponses.push(res);
    const num_pages = Math.ceil(res.totalRecords / default_page_size);
    let promises = [];

    for (let i = 2; i <= num_pages; i++) {
      promises.push(fetch(url));
    }

    let results = await Promise.all(promises);

    results.forEach((res) => {
      processedResponses.push(res.data);
    });

    return processedResponses[0].items;
  } catch {
    return null;
  }
};

export const getItem = async (id: string) => {
  try {
    const res = await fetch(`${apiUrl}/getItem.php?id=${id}`, {
      // headers: { "Content-Type": "application/json" },
    });
    const item = await res.json();

    return item.data;
  } catch {
    return null;
  }
};

export const getItemsByListGeoJSON = async (
  list_id,
  user = undefined,
  password = undefined,
  from,
  to
) => {
  try {
    const item = await (
      await getItemsByList(list_id, user, password, from, to)
    ).json();
    let geojson = convertToGeoJSON(item);
    return geojson;
  } catch {
    return null;
  }
};

export const getItemTypes = async (list_id) => {
  try {
    const res = await fetch(`${apiUrl}/getItemTypes.php?id=${list_id}`);
    const data = await res.json();
    return data.data;
  } catch (err) {
    return err;
  }
};

export const getMaterial = async (id: string) => {
  try {
    let res = await fetch(`${devAPI}/material/${id}`);
    const item = await res.json();
    // console.log("itemID", JSON.stringify(item[0].description));
    return JSON.stringify(item[0].description).replace(/\"/g, "");
  } catch {
    return null;
  }
};
